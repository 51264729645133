<template>
    <div class="app-container">
        <div class="wrap">
            <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
            <div class="add-form">
                <van-form validate-first @failed="onFailed" @submit="onSubmit">
                    <van-field
                            v-model="formInline.materialName"
                            label="物料名称"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入物料名称' }]"
                    />

                    <van-field
                            v-model="formInline.materialCode"
                            label="物料编号"
                            placeholder="请输入"
                            :required="true"
                            :rules="[{ required: true, message: '请输入物料编号' }]"
                    />

                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.categoryName"
                            label="物料类型"
                            placeholder="请选择"
                            @click="showPickerCategory= true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择物料类型' }]"
                    />
                    <van-popup v-model="showPickerCategory" position="bottom">
                        <van-picker
                                show-toolbar
                                :columns="categoryOptions"
                                value-key="dictLabel"
                                @confirm="
                onConfirm($event, 'materialCategory', 'categoryName', 'dictLabel', 'showPickerCategory')
              "
                                @cancel="showPickerCategory = false"
                        />
                    </van-popup>

                    <van-field
                            readonly
                            clickable
                            name="picker"
                            v-model="formInline.typeName"
                            label="物料分类"
                            placeholder="请选择"
                            @click="showPickerType= true"
                            :required="true"
                            :rules="[{ required: true, message: '请选择物料分类' }]"
                    />

                    <van-field
                            v-model="formInline.costPrice"
                            label="成本价"
                            placeholder="请输入"
                            type="number"
                            :required="true"
                            :rules="[{ required: true, message: '请输入成本价' }]"
                    />

                    <van-field
                            v-model="formInline.price"
                            label="售价"
                            placeholder="请输入"
                            type="number"
                            :required="true"
                            :rules="[{ required: true, message: '请输入售价' }]"
                    />

                    <van-field
                            v-model="formInline.materialSpecification"
                            label="物料规格"
                            placeholder="请输入"
                    />

                    <van-field
                            v-model="formInline.materialUnit"
                            label="物料单位"
                            placeholder="请输入"
                    />

                    <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

                    <AppendixVue :businessType="'material'" :needUpload="false" v-if="type != 'add'"/>

                    <div class="line"></div>
                    <van-field name="uploader" label="附件" v-if="type != 'detail'">
                        <template #input>
                            <van-uploader v-model="uploader"  :after-read="afterRead"/>
                        </template>
                    </van-field>

                    <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交</van-button>
                </van-form>
            </div>
        </div>

        <MaterialType :showPop="showPickerType" @handleClose="handleClose"/>
    </div>
</template>
<script>
    import {addMaterial,updateMaterial,selectOne} from '@/api/inventory/material';
    import MaterialType from '@/components/materialType'
    import AppendixVue from '@/views/components/crm/Appendix.vue';

    export default {
        components: {MaterialType,AppendixVue},
        data() {
            return {
                formInline: {
                },
                title: '新建物料',
                id: this.$route.query.id,
                type: this.$route.query.type,
                showPickerCategory: false,
                showPickerType: false,
                categoryOptions:[
                    {dictValue: 0, dictLabel: '自制'},
                    {dictValue: 1, dictLabel: '外购'},
                    {dictValue: 2, dictLabel: '成品'}
                ],
                uploader: [],
                uploadParams: {
                    businessType: 'material',
                    businessId: undefined,
                    file: null,
                },
            };
        },

        mounted() {
            //回显
            this.findDetail()
        },

        methods: {
            handleGoBack() {
                this.$router.replace('/inventory/material');
                this.$router.go(-1)
            },

            //回显
            async findDetail() {
                if(this.type == 'add'){
                    this.formInline = {};
                    this.title = '新建物料';
                }else{
                    let itemDetail = await selectOne(this.id);
                    itemDetail = itemDetail.data
                    this.formInline = itemDetail;
                    this.formInline.whStateName = this.formInline.whState?'启用':'停用'
                    if (itemDetail && this.type == 'edit') {
                        this.title = '编辑物料';
                    } else{
                        this.title = '物料详情';
                    }
                }
            },

            //确认
            onConfirm(event, key, keyName, eventKey, visible) {
                this.formInline[key] = event['dictValue'];
                this.formInline[keyName] = event[eventKey];
                this[visible] = false;
            },

            //备注
            handleContentChange(val) {
                this.formInline.remark = val;
            },

            handleClose(dataList){
                if(dataList && dataList.length > 0){
                    this.formInline.typeId = dataList[0].id
                    this.formInline.typeName = dataList[0].name
                }
                this.showPickerType = false
            },

            async onSubmit() {
                let formdata = this.objectToFormData(this.formInline);
                formdata.set('file',this.uploadParams.file)
                const result = this.formInline.id ? await updateMaterial(formdata) : await addMaterial(formdata);
                if (result.code == 200) {
                    this.$toast.success('添加成功');
                    this.handleGoBack();
                }
            },

            onFailed(errorInfo) {
                console.log('failed', errorInfo);
            },

            afterRead(file) {
                // 此时可以自行将文件上传至服务器
                this.uploadParams.file= file.file;
            },
        },
    };
</script>

<style lang="scss" scoped>
  .base-tit {
    padding: 20px 30px;
    font-size: 24px;
    color: #666666;
  }

  .btn {
    width: 710px;
    height: 100px;
    background: #0091ff;
    border-radius: 6px 6px 6px 6px;
    margin: 118px auto 0;
    font-size: 38px;
  }

  // 导航样式 --start
  ::v-deep .van-hairline--bottom::after {
    border-bottom: none;
  }

  ::v-deep .van-nav-bar .van-icon {
    color: #000;
  }

  ::v-deep .van-nav-bar__content {
    height: 100px;
    line-height: 100px;
  }

  ::v-deep .van-nav-bar__arrow,
  ::v-deep .van-nav-bar .van-icon {
    font-size: 32px;
  }

  // 导航样式 --end
</style>
