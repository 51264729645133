import request from "@/utils/request";
import { serverUrl } from "@/utils/request";

export const baseURL = serverUrl;

// 查询物料列表 除了直接出库、待出库其他都用这个物料列表
export function selectPage(query) {
  return request({
    url: "/inventory/material/selectByPage",
    method: "get",
    params: query
  });
}

// 查询物料列表 直接出库、待出库 掉这个物料的接口
export function selectPage2(query) {
  return request({
    url: "/inventory/materialStock/selectPage",
    method: "get",
    params: query
  });
}

// 生产管理 查询物料的接口
export function selectProMaterialPage(query) {
  return request({
    url: "/inventory/material/selectByPage",
    method: "get",
    params: query
  });
}

// 新增物料
export function addMaterial(data) {
  return request({
    url: "/inventory/material",
    method: "post",
    data: data
  });
}

// 修改物料
export function updateMaterial(data) {
  return request({
    url: "/inventory/material",
    method: "put",
    data: data
  });
}

// 删除物料
export function delMaterial(id) {
  return request({
    url: "/inventory/material/" + id,
    method: "delete"
  });
}

// 查询物料详细
export function selectOne(id) {
  return request({
    url: "/inventory/material/selectOne/" + id,
    method: "get"
  });
}

// 查询部门下拉树结构
export function treeselect() {
  return request({
    url: "/inventory/materialType/selectAll",
    method: "get"
  });
}

// 新增物料分类
export function addLevel(data) {
  return request({
    url: "/inventory/materialType",
    method: "post",
    data: data
  });
}

// 修改物料分类
export function updateLevel(data) {
  return request({
    url: "/inventory/materialType",
    method: "put",
    data: data
  });
}

// 查询物料分类信息
export function selectCategoryOne(id) {
  return request({
    url: "/inventory/materialType/selectOne/" + id,
    method: "get"
  });
}

// 删除物料分类
export function delMaterialType(id) {
  return request({
    url: "/inventory/materialType/" + id,
    method: "delete"
  });
}

// 自动生成编号
export function genNumber() {
  return request({
    url: "/inventory/material/genNumber",
    method: "get"
  });
}

//查询所有物料
export function findAllMaterial() {
  return request({
    url: "/inventory/material/findAllMaterial",
    method: "get"
  });
}
