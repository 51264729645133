import request from "@/utils/request";

// 查询生成表数据
export function listTable(query) {
  return request({
    url: "/code/gen/list",
    method: "get",
    params: query
  });
}
// 查询db数据库列表
export function listDbTable(query) {
  return request({
    url: "/code/gen/db/list",
    method: "get",
    params: query
  });
}

// 查询表详细信息
export function getGenTable(tableId) {
  return request({
    url: "/code/gen/" + tableId,
    method: "get"
  });
}

// 修改代码生成信息
export function updateGenTable(data) {
  return request({
    url: "/code/gen",
    method: "put",
    data: data
  });
}

// 导入表
export function importTable(data) {
  return request({
    url: "/code/gen/importTable",
    method: "post",
    params: data
  });
}

// 预览生成代码
export function previewTable(tableId) {
  return request({
    url: "/code/gen/preview/" + tableId,
    method: "get"
  });
}

// 删除表数据
export function delTable(tableId) {
  return request({
    url: "/code/gen/" + tableId,
    method: "delete"
  });
}

// 生成代码（自定义路径）
export function genCode(tableName) {
  return request({
    url: "/code/gen/genCode/" + tableName,
    method: "get"
  });
}

// 同步数据库
export function synchDb(tableName) {
  return request({
    url: "/code/gen/synchDb/" + tableName,
    method: "get"
  });
}
// 下载附件
export function downLoadAnnex(data) {
  return request({
    url: "/api/system/file/download",
    method: "post",
    data
  });
}
// 删除附件
export function deleteAnnex(params) {
  return request({
    url: "/system/file/" + params,
    method: "delete"
    // params
  });
}
// 负责人
export function fetchLeadPerList(data) {
  return request({
    url: "",
    method: "get",
    data
  });
}

// 查询附件 根据业务id和业务类型分页查询
export function fetchAnnexList(query) {
  return request({
    url: "/system/file",
    method: "get",
    params: query
  });
}
// 操作记录
export function fetchBusinessList(query) {
  return request({
    url: "/system/operRecord",
    method: "get",
    params: query
  });
}
